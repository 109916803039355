import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedItinerary: null,
};

const selectedItinerarySlice = createSlice({
  name: "selectItinerary",
  initialState,
  reducers: {
    setSelectedItinerary: (state, action) => {
      state.selectedItinerary = action.payload;
    },
  },
});

export const { setSelectedItinerary } = selectedItinerarySlice.actions;

export default selectedItinerarySlice.reducer;